import React from 'react'
import '../BestuurPage/Style.css'

const BestuurPage = () => {
  return (
    <>
        <div className='bestuurPageContainer'>
          <div className='bestuurItem'>
            <img src='/images/patrick1.jpg' className='photoOfPresident' alt='pic of president' />
            <div className='descriptionOfBestuurItem'>
              <h1 className='nameOfBestuur'>Patrick Vranckx</h1>
              <h3 className='titleOfBestuur'>President CJJ Belgian Federation</h3>
              <h3 className='gradeOfBestuur'>5 dan Combat Ju-Jutsu</h3>
              <p className='styleOfBestuur'>Style: Combat Ju-Jutsu</p>
            </div>
          </div>
        </div>

        {/*

        <div className="dividerTwoBestuur"></div>

        <div className='BestuursItemTwo'>
          <div className='bestuurItem'>
          <img src='/images/patrick1.jpg' className='photoOfPresident' alt='pic of boardmember' />
            <div className='descriptionOfBestuurItem'>
              <h1 className='nameOfBestuurTwo'>Joeri Winckelmans</h1>
              <h3 className='titleOfBestuurTwo'>Boardmember CJJ Belgian Federation</h3>
              <h3 className='gradeOfBestuurTwo'>1 Dan Combat Ju-Jutsu</h3>
              <p className='styleOfBestuurTwo'>Style: Combat Ju-Jutsu</p>
            </div>
          </div>
        </div>

        <div className="dividerThreeBestuur"></div>

        <div className='BestuursItemThree'>
          <div className='bestuurItem'>
          <img src='/images/patrick1.jpg' className='photoOfPresident' alt='pic of boardmember' />
            <div className='descriptionOfBestuurItem'>
              <h1 className='nameOfBestuurThree'>Celeste Vranckx</h1>
              <h3 className='titleOfBestuurThree'>Boardmember CJJ Belgian Federation</h3>
              <h3 className='gradeOfBestuurThree'>1 Dan Combat Ju-Jutsu</h3>
              <p className='styleOfBestuurThree'>Style: Combat Ju-Jutsu</p>
            </div>
          </div>
        </div>

      */}
      
    </>
  )
}

export default BestuurPage;