import React, { useState } from 'react';
import '../Navbar/Navbar.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const NavbarCjjbf = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  // Close the navbar when a link is clicked
  const handleLinkClick = (url) => {
    setIsOpen(false); // Close the navbar

    // Check if the current URL matches the target URL
    if (location.pathname === url) {
      // If on the same page, scroll to the top
      window.scrollTo(0, 0); // Scroll to top
      //window.location.reload();
      if (url === '/contact') {
        // Scroll to the contact section if it's the same page
        document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // Otherwise, navigate to the new URL
      navigate(url);
    }

   
  };

  return (
    <header>
      <Link to='/' className="logo" onClick={() => handleLinkClick('/')}>
        CJJ BELGIAN FEDERATION
      </Link>
      <nav>
        <ul className={`nav__links ${isOpen ? 'active' : ''}`}>
          <li><Link to="/" onClick={() => handleLinkClick('/')}>HOME</Link></li>
          <li><Link to="/combat" onClick={() => handleLinkClick('/combat')}>COMBAT-JUTSU</Link></li>
          <li><Link to="/bestuur" onClick={() => handleLinkClick('/bestuur')}>BOARD</Link></li>
          <li><Link to="/contact" onClick={() => handleLinkClick('/contact')}>CONTACT</Link></li>
        </ul>
      </nav>
      <button className="toggle-button" onClick={handleToggle}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </button>
    </header>
  );
};

export default NavbarCjjbf;