import React from 'react'; // Add this line if it's missing
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '../src/App.css'
import Footer from './components/Footer/Index';
import NavbarCjjbf from './components/Navbar/Index';
import CombatPage from './components/CombatJuJutsuPage/Index';
import HomePage from './components/HomePage/Index';
import BestuurPage from './components/BestuurPage/Index';
import ScrollToTop from './ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Include the ScrollToTop component here */}
      <div className="App">
        {/* Navbar */}
        <NavbarCjjbf />
        {/* Define routes */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/combat" element={<CombatPage />} />
          <Route path="/bestuur" element={<BestuurPage />} />
          <Route path="/contact" element={<HomePage />} />
        </Routes>
        {/* Footer */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
