import React, {useState} from 'react';
import '../HomePage/Style.css';
import '../HomePage/Kalender';
import kalenderCjjbf from '../HomePage/Kalender';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom

const HomePage = () => {
    // eslint-disable-next-line no-unused-vars
    const [isOpen, setIsOpen] = useState(false);
    
    // Close the navbar when a link is clicked
    const handleLinkClick = (url) => {
        setIsOpen(false); // Close the navbar
        window.location.href = url; // Force navigation to the specific URL
    };

    const handleContactLink = (url) => {
        if (url === '/contact') {
            // Scroll to the contact section if it's the same page
            document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
        }
    };

  return (
    <>
        {/* Home Container */}
        <div className='homePageContainer'>
            <div className='logoAndNameOfWebsite'>
                <img src='/cjjbf-logo.png' alt='combat ju-jutsu belgian federation logo' className='logoImage' />
                <h1 className='titleOfWebsite'>COMBAT JU-JUTSU BELGIAN FEDERATION</h1>
            </div>
            <div className='contactButtonContainer'>
                <Link onClick={() => handleContactLink('/contact')} className='contactButton'>CONTACT US</Link>
            </div>
        </div>

        {/* Slogan of website section */}
        <div className='homePageContainerTwo'>
            <h1 className='sloganOfWebsite'>WELCOME TO THE REAL COMBAT SPORT!</h1>
        </div>

        {/* About Combat Ju-Jutsu Section */}
        <div className='aboutCombatJuJutsu'>
            <div className='titleAndDescriptionOfCombatJuJutsu'>
                <div className='titleAboutCombatJuJutsu'>
                    <h1 className='titleCombat'>ABOUT COMBAT JU-JUTSU</h1>
                </div>
                <div className='descriptionAboutCombatJuJutsu'>
                    <p>This is a sport and a self-defense! This combination of skills to survive and win in a real combat with one or more opponents with the full sports competition experience. The combination of skills and techniques of a sports fight and real combat provides the most universal training for a person!</p>
                    <p>Combat Ju-Jutsu first and foremost is the art of self-defense and applied technique for military and police use.</p>
                    <p>Combat Ju-Jutsu is something that is put between the two strikes!</p>
                </div>
                <div className='combatJuJutsuPageButton'>
                    <Link onClick={() => handleLinkClick('/combat')} className='combatJuJutsuButton'>Combat Ju-Jutsu Page</Link>
                </div>
            </div>
            <div className='imageOfCombatJuJutsu'>
                <img src='/images/SECTIONS1.jpg' alt='about combat ju jutsu' className='imageOfCombat'/>
            </div>
        </div>

        <div className="dividerTwo"></div>

        {/* Kalender Section */}
        <div className='federationContainer'>
            <h1 className='kalenderTitle'>CALENDAR 2024-2025</h1>
            {
                kalenderCjjbf.map((item) => {
                    return (
                        <a key={item.id} className='kalenderItem' href={item.hyperlink} target='_blank' rel='noreferrer'>
                            <p className='titleOfEvent'>{item.title}</p>
                            <p className='dateOfEvent'>{item.dateEvent}</p>
                            <p className='placeOfEvent'>{item.location}</p>
                        </a>
                    )
                })
            }
        </div>

        <div className="dividerThree"></div>

        {/* Headquarters and contact section */}
        <div id="contact" className='headquartersOfTheFederation'>
            <img src='/cjjbf-logo.png' className='logoHeadQuarter' alt='logo of cjjbf' />
            <div className='adresOfTheHeadQuarters'>
                <h3 className='titleAdres'>HEADQUARTERS</h3>
                <p>Diensten Centrum Victor de Bruyne</p>
                <p>Paul Henri Spaaklaan 5 -</p>
                <p>2660 Hoboken (Antwerpen)</p>
                <h3 className='titleContact'>CONTACT</h3>
                <p>Phone: +32 485 12 02 54</p>
                <p>E-mail: cjjbfcontact@gmail.com</p>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5003.299256074389!2d4.344284776322486!3d51.17024853527762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3f3e5739b6d49%3A0x6bf71602287130b7!2sDienstencentrum%20Victor%20De%20Bruyne%20-%20Zorgbedrijf%20Antwerpen!5e0!3m2!1snl!2sbe!4v1726344693563!5m2!1snl!2sbe" width="500" height="320" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='headquartersOfCjjbf' className='googleMapsCjjbf'></iframe>
        </div>
    </>
  );
}

export default HomePage;